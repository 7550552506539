import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import "./FormCommon.css";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactQuill from "react-quill";
import UserDetailsModal from "../users/UserDetailsModal";
import { API_URL } from "../config/api_urls";
import { get } from "../config";
import { useNavigate } from "react-router";
import { Disabled, PaddingTop } from "../../../../Constant";

function FormCommon({
  isGallery,
  isEvenVideo,
  isModal,
  isaboutme,
  isaddresline1,
  isaddresline2,
  ispincode,
  isEditing,
  userRoles,
  formDataConfig,
  setFormDataConfig,
  PlaceConfig,
  onSave,
  title,
  isModel,
  addressConfig,
  AboutConfig,
  LocationConfig,
  CheckboxConfig,
  attribute,
  setAttributeConfig,
  attributeConfig,
  preference,
  preferenceConfigue,
  setPreferenceConfigue,
  sociaMedia,
  socialConfigue,
  setSocialConfigue,
  isPreview,
  setIsPreview,
}) {
  const [formData, setFormData] = useState(formDataConfig.initialState || {});
  // const [addressData, setaddressData] = useState(addressConfig.initialStates || {});
 
  const navigate = useNavigate();

  const [addressFormData, setAddressFormData] = useState({});
  const [aboutFormData, setaboutFormData] = useState({});
  const [LocationFormData, setLocationFormData] = useState({});

  const [inputsEntered, setInputsEntered] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [videoFile, setVideoFile] = useState([]);
  const [aboutMe, setAboutMe] = useState("");

  const [videoPreview, setVideoPriview] = useState([]);

  const [addressline1, setaddressline1] = useState("");
  const [addressline2, setaddressline2] = useState("");
  const [pincode, setpincode] = useState("");

  const [modal, setModal] = useState(false);
  const [profilePreview, setProfilePreview] = useState();
  const [coverPreview, setCoverPreview] = useState();

  const [category, setCategory] = useState();
  const [categoryName, setCategoryname] = useState();
  const [userRole, setUserRole] = useState();
  const [rolename, setRolename] = useState();
  const [profiles, setprofiles] = useState();
  const [profilePreferences, setProfilePreferences] = useState([]);
  const [ProfileAttributes, setProfileAttributes] = useState([]);
  const [matchedAttributes, setMatchedAttributes] = useState([]);
  const [ProfileSocialmedia, setProfileSocialmedia] = useState([]);
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropSocial,setDropSocial] = useState(false)
  const [dropPref,setDropPref] = useState(false)
  // const [isPreview,setIsPreview] = useState(false)
  // const [socialFormData,setSocialFormData] = useState
  const [selectedOptions, setSelectedOptions] = useState([]);

  console.log(preference);

  useEffect(() => {
    if (formDataConfig) {
      setFormData(formDataConfig.initialState || {});
    }
  }, [formDataConfig]);

  useEffect(() => {
    if (addressConfig) {
      setAddressFormData(addressConfig.initialState || {});
    }
  }, [addressConfig]);

  console.log("addressformdata in formcommon", addressFormData);

  useEffect(() => {
    if (AboutConfig) {
      setaboutFormData(AboutConfig.initialState || {});
    }
  }, [AboutConfig]);

  useEffect(() => {
    if (LocationConfig) {
      setLocationFormData(LocationConfig.initialState || {});
    }
  }, [LocationConfig]);

  console.log("aboutformdata in formcommon", aboutFormData);

  const fetchCategory = useCallback(() => {
    get(API_URL.USER_CATEGORY.USER_CATEGORY_GET)
      .then((response) => {
        console.log(response.data);
        setCategory(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchUserRole = async () => {
    await get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((res) => {
        console.log(res.data);
        setUserRole(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  console.log('formcongif',formDataConfig);
  console.log('form',formData); 


  	const fetchUsersList = useCallback(() => { 
		get(API_URL.USERS.GET_USERS)
			.then((response) => {
				if (response?.data.length >= 0) {
					setUserData(response.data[response.data.length - 1]);
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

  console.log(userData);
  console.log(formData);

  //profile
  const fetchProfile = useCallback(() => {
    setIsLoading(true);
    get(API_URL.PROFILE.GET_PROFILE)
      .then((response) => {
        setprofiles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profile:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formData]);

  console.log(attribute);

  const compareAttributes = useCallback(() => {
    if (attributeConfig.length > 0 && attribute.length > 0) {
      const matched = attribute
        .filter((attr) =>
          attributeConfig.some(
            (userAttr) =>
              attr.value === userAttr.attribute_id &&
              userAttr.user_name === formData.name
          )
        )
        .map((attr) => {
          const matchedUserAttr = attributeConfig.find(
            (userAttr) =>
              userAttr.attribute_id === attr.value &&
              userAttr.user_name === formData.name
          );

          return {
            ...attr,
            ...matchedUserAttr,
          };
        });
      setMatchedAttributes(matched);
    }
  }, [attributeConfig, attribute, formData.name]);
  console.log(matchedAttributes);

  // Fetch category when component mounts
  useEffect(() => {
    fetchCategory();
    fetchUserRole();
    fetchProfile();
    if (attributeConfig) {
      console.log(attributeConfig);
      compareAttributes();
    }
  }, [formData, isPreview]);

  // Update categoryName when category or formData.category_id changes
  useEffect(() => {
    if (isPreview) {
      const categoryName = category.find(
        (i) =>
          i.id === (isEditing ? formData.category_id : userData?.category_id)
      )?.name;
      setCategoryname(categoryName);
    }
    console.log(userRole);

    if (isPreview) {
      const rolename = userRole.find(
        (i) =>
          i.id === (isEditing ? formData.user_role_id : userData?.user_role_id)
      )?.role;
      setRolename(rolename);
    }

    console.log(profiles);

    if (isPreview) {
      const matchedProfiles = profiles.filter((profile) => {
        // Log each profile during the filter process
        console.log("Checking profile:", profile);
        console.log(formData);
        console.log(userData);

        return (
          profile.user_uuid === (isEditing ? formData.uuid : userData.uuid)
        );
      });

      console.log(matchedProfiles);

      if (isPreview) {
        setProfilePreferences(
          matchedProfiles.map((profile) => {
            return profile.preferences;
          })
        );
        setProfileAttributes(
          matchedProfiles.map((profile) => {
            return profile.attributes;
          })
        );

        setProfileSocialmedia(
          matchedProfiles.map((profile) => {
            return profile.social_media;
          })
        );

        setAbout(
          matchedProfiles.map((profile) => {
            return profile.about;
          })
        );
      }
    }
  }, [category, formData, userData]);

  console.log(about);

  console.log(categoryName);
  console.log(rolename);
  console.log(profilePreferences);
  console.log(ProfileAttributes);
  console.log(preferenceConfigue);
  console.log(attributeConfig);
  console.log(socialConfigue);

  //profilepreference
  const allpreference =
    Array.isArray(profilePreferences) && profilePreferences.length > 0
      ? profilePreferences[0]
      : null;

  console.log(allpreference); 

  const handleInputChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
    setInputsEntered(true);
  };

  const handleFieldChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setInputsEntered(true);
  };

  const handleAddress = (e) => {
    const { name, value, files, type, checked } = e.target;
    setAddressFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
    setInputsEntered(true);
  };

  const handleLocation = (e) => {
    const { name, value, files, type, checked } = e.target;
    setLocationFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
    setInputsEntered(true);
  };
  console.log("addressfordata", addressFormData);

  const handleValueChange = (e, attributeId) => {
    const { value } = e.target;

    // Find if the attribute has already been added to the config
    const existingAttribute = attributeConfig.find(
      (attr) => attr.attribute_id === attributeId
    );

    if (existingAttribute) {
      // If the attribute exists, update its value
      const updatedConfig = attributeConfig.map((attr) =>
        attr.attribute_id === attributeId ? { ...attr, value } : attr
      );
      setAttributeConfig(updatedConfig);
    } else {
      // If the attribute does not exist, add it to the config
      setAttributeConfig([
        ...attributeConfig,
        { attribute_id: attributeId, value },
      ]);
    }
    setInputsEntered(true);

    // Log or handle the output here
    console.log({ attribute_id: attributeId, value });
  };

  const handleSocialMedia = (e, attributeId) => {
    const { value } = e.target;
  
    // Find if the attribute has already been added to the config
    const existingAttribute = socialConfigue.find(
      (attr) => attr.socialmedia_id === attributeId
    );
  
    if (existingAttribute) {
      // If the attribute exists, update its link value
      const updatedConfig = socialConfigue.map((attr) =>
        attr.socialmedia_id === attributeId ? { ...attr, link: value } : attr
      );
      setSocialConfigue(updatedConfig);
    } else {
      // If the attribute does not exist, add it to the config with the link value
      setSocialConfigue([
        ...socialConfigue,
        { socialmedia_id: attributeId, link: value },
      ]);
    }
  
    setInputsEntered(true); // Optional state update to manage form submission
    // Log or handle the output
    console.log({ socialmedia_id: attributeId, link: value });
  };
  


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownSocial = () => {
    setDropSocial(!dropSocial);
  };

  const preferenceDropdown = () => {
    setDropPref(!dropPref)
  }


  const handlePreferenceSelect = (prefId) => {
    const alreadySelected = preferenceConfigue.find(
      (pref) => pref.preference_ids === prefId
    );

    if (alreadySelected) {
      // If already selected, remove it (deselect)
      setPreferenceConfigue(
        preferenceConfigue.filter((pref) => pref.preference_ids !== prefId)
      );
    } else {
      // Add a new attribute to the selected list if not already selected
      setPreferenceConfigue([
        ...preferenceConfigue,
        { preference_ids: prefId, value: "" },
      ]);
    }
    setInputsEntered(true);
  };

  const handleAbout = (e) => {
    const { name, value, files, type, checked } = e.target;
    setaboutFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
    setInputsEntered(true);
  };

  console.log("abutformdata", aboutFormData);

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
    setInputsEntered(true);
  };

  //preview
  const handlePrintPreview = (e) => {
    e.preventDefault();

    // Check if all fields are filled before showing the preview
    if (formData) {
      // Show print preview by opening the print dialog
      window.print();
    } else {
      alert("Please fill out all fields before previewing.");
    }
  };

  //gallery
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // convert FileList to an array
    const names = files.map((file) => file.name); // get the file names

    const imageUrl = files.map((file) => URL.createObjectURL(file));
    setImagePreview(imageUrl);

    setFileNames(files);
    setInputsEntered(true);
  };

  const handleAboutMe = (e) => {
    setAboutMe(e.target.value);
  };

  //button
  const handleButtonClick = () => {
    const newSocialMediaField = {
      name: "social_media_id",
      label: "Social media",
      type: "select",
      options: [],
      required: false,
    };

    const newSocialMediaLinkField = {
      name: `social_media_link_${formDataConfig.fields.length}`,
      label: "Social Media Link",
      type: "text",
      placeholder: "Enter the social media link",
      required: false,
    };

    // Get the index of the "social_media_id" field
    setFormDataConfig((prevConfig) => ({
      ...prevConfig,
      fields: [
        ...prevConfig.fields,
        newSocialMediaField,
        newSocialMediaLinkField,
      ],
    }));
  };

  //event video
  const handleEventVideo = (e) => {
    const files = Array.from(e.target.files);

    const imageUrl = files.map((file) => URL.createObjectURL(file));
    setVideoPriview(imageUrl);

    setVideoFile(files);
    setInputsEntered(true);
  };
  // console.log(videoFile);

  // console.log(fileNames);

  const removeImage = (indexToRemove) => {
    setImagePreview((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeVideo = (indexToRemove) => {
    setVideoPriview((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleNavigateToUsers = () => {
    navigate(`${process.env.PUBLIC_URL}/users/list/:layout`);
  };

  //modal
  const toggle = () => {
    setModal(!modal);
    // handleNavigateToUsers()
  };

  const toggleModal = () => {
    setIsPreview(true);
    setModal(!modal);
    fetchUsersList();
    // handleNavigateToUsers()
  };

  const handleDone = () => {
    handleNavigateToUsers();
  };

  const toggleClose = () => setModal(!modal);

  const handleSave = () => {
    if (!inputsEntered) {
      toast.warning("Please enter the inputs!");
      return;
    }

    if (onSave)
      onSave(
        formData,
        fileNames,
        videoFile,
        aboutMe,
        addressline1,
        addressline2,
        pincode,
        addressFormData,
        aboutFormData
      );
  };

  // const renderField = (field) => {
  //   if (!field) return null;
  //   const fieldValue = formData[field.name] || [];
  //   switch (field.type) {
  //     case "file":
  //       const renderImage =
  //         typeof fieldValue === "string" ? (
  //           <img src={`${fieldValue}`} className="dummy-image" alt="IMAGE" />
  //         ) : null;
  //       return (
  //         <div className="file-field-container input-field d-flex">
  //           <input
  //             type="file"
  //             id={field.name}
  //             className="styled-input"
  //             name={field.name}
  //             onChange={handleInputChange}
  //             required={field.required}
  //           />
  //           <div className="p-3">{renderImage}</div>
  //         </div>
  //       );

  //     case "checkbox":
  //       return (
  //         <label className=" d-flex ">
  //           <input
  //             type="checkbox"
  //             value={field.name}
  //             id={field.name}
  //             name={field.name}
  //             checked={!!formData[field.name]}
  //             onChange={handleInputChange}
  //             required={field.required}
  //           />
  //         </label>
  //       );
  //     case "select":
  //       return (
  //         <select
  //           name={field.name}
  //           value={formData[field.name] || ""}
  //           onChange={handleInputChange}
  //           className="styled-input"
  //           required={field.required}
  //         >
  //           {Array.isArray(field.options) ? (
  //             field.options.map((option) => (
  //               <option
  //                 key={option.value}
  //                 value={option.value}
  //                 disabled={option.disabled}
  //               >
  //                 {option.label}
  //               </option>
  //             ))
  //           ) : (
  //             <option disabled>No options available</option>
  //           )}
  //         </select>
  //       );
  //     case "multiselect":
  //       return (
  //         <Select
  //           isMulti
  //           name={field.name}
  //           options={field.options}
  //           value={field.options.filter((option) =>
  //             fieldValue.includes(option.value)
  //           )}
  //           onChange={(selectedOptions) =>
  //             handleMultiSelectChange(selectedOptions, field.name)
  //           }
  //           className="basic-multi-select"
  //           classNamePrefix="select"
  //         />
  //       );

  //     case "group":
  //       return (
  //         <div className="range-group">
  //           <Row>
  //             {field.fields.map((subField) => (
  //               <Col key={subField.name}>
  //                 <input
  //                   type={subField.type}
  //                   id={subField.name}
  //                   className="styled-input"
  //                   name={subField.name}
  //                   placeholder={subField.placeholder}
  //                   onChange={handleInputChange}
  //                   value={formData[subField.name] || ""}
  //                   required={field.required}
  //                 />
  //               </Col>
  //             ))}
  //           </Row>
  //         </div>
  //       );
  //     default:
  //       return (
  //         <input
  //           type={field.type}
  //           id={field.name}
  //           className="styled-input"
  //           name={field.name}
  //           placeholder={field.placeholder}
  //           onChange={handleInputChange}
  //           value={formData[field.name] || ""}
  //           required={field.required}
  //         />
  //       );
  //   }
  // };

  const renderField = (field) => {
    if (!field) return null;
    const fieldValue = formData[field.name] || [];

    switch (field.type) {
      case "file":
        const renderImage =
          typeof fieldValue === "string" ? (
            <img src={fieldValue} className="dummy-image" alt="IMAGE" />
          ) : null;
        return (
          <div className="file-field-container input-field d-flex">
            <input
              type="file"
              id={field.name}
              className="styled-input"
              name={field.name}
              onChange={handleInputChange}
              required={field.required}
            />
            <div className="p-3">{renderImage}</div>
          </div>
        );

      case "group":
        return (
          <div className="range-group">
            <Row>
              {field.fields.map((subField) => (
                <Col key={subField.name}>
                  <input
                    type={subField.type}
                    id={subField.name}
                    className="styled-input"
                    name={subField.name}
                    placeholder={subField.placeholder}
                    onChange={handleInputChange}
                    value={formData[subField.name] || ""}
                    required={field.required}
                  />
                </Col>
              ))}
            </Row>
          </div>
        );

      case "checkbox":
        return (
          <label className="d-flex">
            <input
              type="checkbox"
              value={field.name}
              id={field.name}
              name={field.name}
              checked={!!formData[field.name]}
              onChange={handleInputChange}
              required={field.required}
            />
          </label>
        );

      case "select":
        return (
          <select
            name={field.name}
            value={formData[field.name] || ""}
            onChange={(e) => {
              handleInputChange(e);
              if (field.onChange) field.onChange(e.target.value); // Triggering field's onChange
            }}
            className="styled-input"
            required={field.required}
          >
            {isModel ? (
              Array.isArray(field.options) ? (
                field.options.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </option>
                ))
              ) : (
                <option disabled>No options available</option>
              )
            ) : Array.isArray(field.options) ? (
              field.options.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))
            ) : (
              <option disabled>No options available</option>
            )}
          </select>
        );

      case "multiselect":
        return (
          <Select
            isMulti
            name={field.name}
            options={field.options}
            value={field.options.filter((option) =>
              fieldValue.includes(option.value)
            )}
            onChange={(selectedOptions) =>
              handleMultiSelectChange(selectedOptions, field.name)
            }
            className="basic-multi-select"
            classNamePrefix="select"
          />
        );

      case "quill-editor":
        return (
          <div key={field.name}>
            {/* <label>{field.label}</label> */}
            <ReactQuill
              value={formData[field.name]}
              onChange={(value) => handleFieldChange(field.name, value)}
              placeholder={field.placeholder}
            />
          </div>
        );

      case "button":
        return (
          <button
            key={field.index}
            type="button"
            onClick={() => handleButtonClick(field.action)} // Handle button click based on action
            // style={styles.addButton}
            className="btn border"
          >
            {field.placeholder}
          </button>
        );

      case "group":
        return (
          <div className="range-group">
            <Row>
              {field.fields.map((subField) => (
                <Col key={subField.name}>
                  <input
                    type={subField.type}
                    id={subField.name}
                    className="styled-input"
                    name={subField.name}
                    placeholder={subField.placeholder}
                    onChange={handleInputChange}
                    value={formData[subField.name] || ""}
                    required={field.required}
                  />
                </Col>
              ))}
            </Row>
          </div>
        );

      default:
        return (
          <input
            type={field.type}
            id={field.name}
            className="styled-input"
            name={field.name}
            placeholder={field.placeholder}
            onChange={handleInputChange}
            value={formData[field.name] || ""}
            required={field.required}
          />
        );
    }
  };

  const style = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
  };

  // const profileUrl = URL.createObjectURL(formData.profile_image);
  //   setProfilePreview(profileUrl);

  //   const coverUrl = URL.createObjectURL(formData.cover_image);
  //   setCoverPreview(coverUrl);

  //   console.log(profilePreview,coverPreview);

  return (
    <Fragment>
      <Card>
        <Form
          className="form theme-form p-3"
          onSubmit={(e) => e.preventDefault()}
        >
          <h4 className="ms-3 mt-3">{title}</h4>

          <CardBody>
            {Array.isArray(formDataConfig.fields) &&
              formDataConfig.fields.map((field, index) =>
                index % 2 === 0 ? (
                  <Row key={field.name}>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="fw-bold" htmlFor={field.name}>
                          {field.label}
                        </Label>
                        {renderField(field)}
                      </FormGroup>
                    </Col>
                    {formDataConfig.fields[index + 1] && (
                      <Col md={6}>
                        <FormGroup>
                          <Label
                            className="fw-bold"
                            htmlFor={formDataConfig.fields[index + 1].name}
                          >
                            {formDataConfig.fields[index + 1].label}
                          </Label>
                          {renderField(formDataConfig.fields[index + 1])}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                ) : null
              )}


{LocationConfig &&  Array.isArray(LocationConfig.fields) &&
  LocationConfig.fields.map((field, index) =>
    index % 2 === 0 ? (
      <Row key={field.name}>
        <Col md={6}>
          <FormGroup>
            <Label className="fw-bold" htmlFor={field.name}>
              {field.label}
            </Label>
            {renderField(field)}
          </FormGroup>
        </Col>
        {LocationConfig.fields[index + 1] && (
          <Col md={6}>
            <FormGroup>
              <Label
                className="fw-bold"
                htmlFor={LocationConfig.fields[index + 1].name}
              >
                {LocationConfig.fields[index + 1].label}
              </Label>
              {renderField(LocationConfig.fields[index + 1])}
            </FormGroup>
          </Col>
        )}
      </Row>
    ) : null
  )}


              

            {attributeConfig && (
              <>
                <Row>
                  <Col lg={6}>
                    <div style={styles.container}>
                      <div style={styles.dropdown} onClick={toggleDropdown}>
                        <div style={styles.selectLabel}>
                          Select Measurements
                        </div>
                        <div style={styles.arrow}>⮟</div>
                      </div>

                      {/* List of Attributes with Input Fields */}
                      {(isDropdownOpen || isEditing) && (
                        <div style={styles.attributeList}>
                          {attribute.map((attribute) => (
                            <div key={attribute.id} style={styles.attributeRow}>
                              {/* Attribute Label */}
                              <button style={styles.attributeLabel}>
                                {attribute.label}
                              </button>

                              {/* Input Field */}
                              <input
                                type="text"
                                placeholder="Enter value"
                                onChange={(e) =>
                                  handleValueChange(e, attribute.value)
                                }
                                value={
                                  attributeConfig.find(
                                    (attr) =>
                                      attr.attribute_id === attribute.value
                                  )?.value || ""
                                }
                                style={styles.input}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div style={styles.container}>
                      <div style={styles.dropdown} onClick={preferenceDropdown}>
                        <div style={styles.selectLabel}>
                          Select Preference
                        </div>
                        <div style={styles.arrow}>⮟</div>
                      </div>
                        {(dropPref || isEditing) && 
                        <div className="d-flex flex-column" style={{padding: "10px",borderRadius: "10px",marginBottom: "20px"}}>
                        {preference.map((attribute) => (
                          <button
                            className="btn m-1 border border-light text-start"
                            type="button"
                            key={attribute.value}
                            onClick={() =>
                              handlePreferenceSelect(attribute.value)
                            }
                            style={{
                              marginRight: "10px",
                              padding: "10px",
                              backgroundColor: preferenceConfigue.some(
                                (attr) =>
                                  attr.preference_ids === attribute.value
                              )
                                ? "#457b9d"
                                : "transparent",
                              color: preferenceConfigue.some(
                                (attr) =>
                                  attr.preference_ids === attribute.value
                              )
                                ? "white"
                                : "black",
                              border: "1px solid blue",
                            }}
                          >
                            {attribute.label}
                          </button>
                        ))}
                        </div>}
                     
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div style={styles.container}>
                      <div style={styles.dropdown} onClick={dropdownSocial}>
                        <div style={styles.selectLabel}>Select Socialmedia</div>
                        <div style={styles.arrow}>⮟</div>
                      </div>

                      {/* List of Attributes with Input Fields */}
                      {(dropSocial || isEditing) && (
                        <div style={styles.attributeList}>
                          {sociaMedia.map((attribute) => (
                            <div key={attribute.id} style={styles.attributeRow}>
                              {/* Attribute Label */}
                              <button style={styles.attributeLabel}>
                                {attribute.label}
                              </button>

                              {/* Input Field */}
                              <input
                                type="text"
                                placeholder="Enter value"
                                onChange={(e) =>
                                  handleSocialMedia(e, attribute.value)
                                }
                                value={
                                  socialConfigue.find(
                                    (attr) =>
                                      attr.socialmedia_id === attribute.value
                                  )?.link || ""
                                }
                                style={styles.input}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}></Col>
                </Row>
              </>
            )}

            {addressConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">Address line1</Label>
                <Input
                  type="text"
                  name="address_line1" // This should match the key in the state object
                  placeholder={
                    isEditing
                      ? "Edit your address line 1"
                      : "Enter your address line 1"
                  }
                  onChange={handleAddress}
                  value={addressFormData.address_line1 || ""} // Add value to ensure it's controlled
                />
              </FormGroup>
            )}

            {addressConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">Address line2</Label>
                <Input
                  type="text"
                  name="address_line2"
                  placeholder={
                    isEditing
                      ? "Edit your address line 2"
                      : "Enter your address line 2"
                  }
                  onChange={handleAddress}
                  value={addressFormData.address_line2 || ""} // Ensure controlled input
                />
              </FormGroup>
            )}

            {addressConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">Pincode</Label>
                <Input
                  type="number"
                  name="pincode"
                  placeholder={
                    isEditing ? "Edit your pincode" : "Enter your pincode"
                  }
                  onChange={handleAddress}
                  value={addressFormData.pincode || ""} // Ensure controlled input
                />
              </FormGroup>
            )}
            {AboutConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">About me</Label>
                <Input
                  type="textarea"
                  name="about"
                  placeholder={
                    isEditing ? "Edit your about me" : "Enter your about me"
                  }
                  onChange={handleAbout}
                  value={aboutFormData.about || ""} // Ensure controlled input
                />
              </FormGroup>
            )}

            {isGallery && (
              <FormGroup
                controlId="formFileMultiple"
                className="mb-3 mt-2 d-flex flex-column"
              >
                <Label className="mt-3 fw-bold">Input Video</Label>
                <input
                  className="border rounded"
                  type="file"
                  accept="video/*"
                  multiple
                  onChange={handleEventVideo}
                />

                <div
                  className="d-flex my-2"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  {videoPreview.map((video, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <video
                        autoPlay
                        src={video}
                        style={{ borderRadius: "5px" }}
                        alt={`preview ${index}`}
                        height="100"
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          background: "black",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => removeVideo(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>

                <Label className="fw-bold">Input Gallery Images</Label>
                <input
                  className="border rounded"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />

                <div
                  className="d-flex mt-2"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  {imagePreview.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={image}
                        style={{ borderRadius: "5px" }}
                        alt={`preview ${index}`}
                        height="100"
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          background: "black",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => removeImage(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </FormGroup>
            )}

            {isEvenVideo && (
              <FormGroup
                controlId="formFileMultiple"
                className="mb-3 mt-2 d-flex flex-column"
              >
                <Label className="fw-bold">Input Video</Label>
                <input
                  className="border"
                  type="file"
                  accept="video/*"
                  multiple
                  onChange={handleEventVideo}
                />

                <div
                  className="d-flex my-2"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  {videoPreview.map((video, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <video
                        autoPlay
                        src={video}
                        style={{ borderRadius: "5px" }}
                        alt={`preview ${index}`}
                        height="100"
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          background: "black",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => removeVideo(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </FormGroup>
            )}

{CheckboxConfig &&  Array.isArray(CheckboxConfig.fields) &&
  CheckboxConfig.fields.map((field, index) =>
    index % 2 === 0 ? (
      <Row key={field.name}>
        <Col md={6}>
          <FormGroup>
            <Label className="fw-bold" htmlFor={field.name}>
              {field.label}
            </Label>
            {renderField(field)}
          </FormGroup>
        </Col>
        {CheckboxConfig.fields[index + 1] && (
          <Col md={6}>
            <FormGroup>
              <Label
                className="fw-bold"
                htmlFor={CheckboxConfig.fields[index + 1].name}
              >
                {CheckboxConfig.fields[index + 1].label}
              </Label>
              {renderField(CheckboxConfig.fields[index + 1])}
            </FormGroup>
          </Col>
        )}
      </Row>
    ) : null
  )}

            {/* {isEvenVideo && <FormGroup
              controlId="formFileMultiple"
              className="mb-3 mt-2 d-flex flex-column"
            >
              <Label>Input Video</Label>
              <input className="border" type="file" accept="video/*" multiple onChange={handleEventVideo} />
            </FormGroup>} */}
          </CardBody>

          {!isModal && (
            <div className="d-flex">
              <div className="w-100 d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    type: "submit",
                    onClick: handleSave,
                  }}
                >
                  Save Changes
                </Btn>
              </div>
              {isGallery && (
                <div className="w-100 d-flex justify-content-end">
                  {isPreview || isEditing ? (
                    <Btn
                      attrBtn={{
                        color: "primary",
                        type: "submit",
                        onClick: toggleModal,
                      }}
                    >
                      Preview
                    </Btn>
                  ) : (
                    <Btn
                      attrBtn={{
                        color: "primary",
                        type: "submit",
                        onClick: toggleModal,
                        Disabled,
                      }}
                    >
                      Preview
                    </Btn>
                  )}
                </div>
              )}
            </div>
          )}
        </Form>
      </Card>
      <div>
        {isPreview && (
          <Modal
            isOpen={modal}
            toggle={toggle}
            style={{ maxWidth: "800px", overflowX: "hidden" }}
            scrollable
          >
            <ModalHeader toggle={toggle}>User Preview</ModalHeader>
            <ModalBody
              className="container"
              style={{
                padding: "20px",
                width: "100%",
                height: "100vh",
                overflowX: "hidden",
              }}
            >
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <div style={{ position: "relative" }}>
                    <div className="row">
                      <img
                        src={
                          isEditing
                            ? formData.cover_image
                            : userData.cover_image
                        }
                        alt="Cover Image"
                        style={{
                          width: "100%",
                          // height: "350px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="row justify-content-center"
                    style={{ position: "relative", top: "-250px" }}
                  >
                    <div className="col-auto">
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            isEditing
                              ? formData.profile_image
                              : userData.profile_image
                          }
                          alt="User Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            border: "1px solid #ddd",
                            borderRadius: "50%",
                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                            padding: "5px",
                            transition: "transform 0.2s",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.2)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1.0)")
                          }
                        />
                      </div>

                      {/* <div className="col-auto">
                <h3
                  className="text-start"
                  style={{ color: "black", marginLeft: "40px" }}
                >
                  {formData.name}
                </h3>
              </div> */}
                    </div>
                  </div>

                  <div style={{ position: "relative", top: "-120px" }}>
                    <div className="">
                      <h5>
                        <i className="fa-solid fa-id-card m-3"></i>BASIC INFO
                      </h5>
                      <div className="" style={{ marginLeft: "15px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {/* First Row */}
                          <div style={{ flex: "0 0 48%" }}>
                            <p>
                              <strong>Name :</strong> {formData.name}
                            </p>
                          </div>
                          <div style={{ flex: "0 0 48%" }}>
                            <p>
                              <strong>Gender :</strong> {formData.gender}
                            </p>
                          </div>

                          {/* Second Row */}
                          <div style={{ flex: "0 0 48%" }}>
                            <p>
                              <strong>DOB :</strong> {formData.date_of_birth}
                            </p>
                          </div>
                          <div style={{ flex: "0 0 48%" }}>
                            <p>
                              <strong>Age :</strong> {formData.age}
                            </p>
                          </div>

                          {/* Last Row */}
                          {/* <div style={{ flex: "0 0 100%" }}>
                  <p>
                    <strong>Category :</strong> {Category}
                  </p>
                </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <h5>
                        <i class="fa-solid fa-square-phone m-3"></i>CONTACT ME
                      </h5>
                      <div
                        className="d-flex flex-row m-3"
                        style={{ gap: "20px" }}
                      >
                        <div className="col-4 rounded " style={style}>
                          <p
                            style={{
                              marginLeft: "0px",
                              marginTop: "10px",
                              paddingLeft: "7px",
                            }}
                          >
                            <i class="fa-solid fa-phone"></i>{" "}
                            {formData.phone_number || "N/A"}
                          </p>
                        </div>
                        <div className="col-4  rounded" style={style}>
                          <p
                            style={{
                              marginLeft: "0px",
                              marginTop: "10px",
                              paddingLeft: "7px",
                            }}
                          >
                            <i class="fa-solid fa-envelope"></i>{" "}
                            {formData.email || "N/A"}
                          </p>
                        </div>
                        <div className="col-4  rounded" style={style}>
                          <p
                            style={{
                              marginLeft: "0px",
                              marginTop: "10px",
                              paddingLeft: "7px",
                            }}
                          >
                            <i class="fa-brands fa-whatsapp"></i>{" "}
                            {formData.whatsapp_number || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="m-4"
                      style={{ display: "flex", flex: "wrap", gap: "10px" }}
                    >
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>Category :</strong> {categoryName}
                        </p>
                      </div>
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>User Role :</strong> {rolename}
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex-column m-4"
                      style={{ display: "flex", flex: "wrap", gap: "10px" }}
                    >
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>Address Line 1 :</strong>{" "}
                          {addressFormData.address_line1}
                        </p>
                      </div>
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>Address Line 2 :</strong>{" "}
                          {addressFormData.address_line2}
                        </p>
                      </div>
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>Pincode :</strong> {addressFormData.pincode}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <h5>
                        <i className="fa-solid fa-mug-hot m-3"></i>MY
                        PREFERENCES
                      </h5>
                      <div className="col-6 rounded" style={style}>
                        {allpreference && allpreference.length > 0 ? (
                          <div style={{ marginLeft: "20px" }}>
                            {allpreference.map((pref, index) => (
                              <button
                                key={index}
                                style={{
                                  margin: "5px",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  backgroundColor: "",
                                  color: "black",
                                  border: "1px solid pink",
                                  cursor: "pointer",
                                }}
                                disabled
                              >
                                {pref}
                              </button>
                            ))}
                          </div>
                        ) : (
                          <p style={{ marginLeft: "20px" }}>N/A</p>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <h5>
                        <i className="fa-brands fa-creative-commons-by m-3"></i>
                        MY ATTRIBUTE
                      </h5>
                      <div className="col-6 rounded " style={style}>
                        {matchedAttributes && matchedAttributes.length > 0 ? (
                          <div
                            className="d-flex"
                            style={{ marginLeft: "20px" }}
                          >
                            {matchedAttributes.map((attr, index) => (
                              <div
                                className="d-flex"
                                key={index}
                                style={{ marginBottom: "10px" }}
                              >
                                <button
                                  // key={key}
                                  style={{
                                    margin: "5px",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    backgroundColor: "",
                                    color: "black",
                                    border: "1px solid pink",
                                    cursor: "pointer",
                                  }}
                                  disabled
                                >
                                  {attr.label}: {attr.value} {attr.enter_unit}
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p style={{ marginLeft: "20px" }}>N/A</p>
                        )}
                      </div>
                    </div>
                    <div className="m-3">
                      <h5>SOCIAL MEDIA LINKS</h5>
                      <div
                        className="d-flex align-items-center justify-content-start m-3"
                        style={{
                          width: "100%",
                          height: "30px",
                        }}
                      >
                        {ProfileSocialmedia.length > 0 ? (
                          <div
                            className=""
                            style={{ display: "flex", gap: "10px" }}
                          >
                            {ProfileSocialmedia.map((item, index) =>
                              Object.entries(item).map(([key, value]) => {
                                return (
                                  <a
                                    key={`${index}-${key}`}
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      border: "1px solid pink",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {key.toLowerCase() === "instagram" && (
                                      <i
                                        className="fa-brands fa-instagram m-2"
                                        style={{ fontSize: "18px" }}
                                      ></i>
                                    )}
                                    {key.toLowerCase() === "linkedin" && (
                                      <i
                                        className="fa-brands fa-linkedin m-2"
                                        style={{ fontSize: "18px" }}
                                      ></i>
                                    )}
                                    {key.toLowerCase() === "facebook" && (
                                      <i
                                        className="fa-brands fa-facebook m-2"
                                        style={{ fontSize: "18px" }}
                                      ></i>
                                    )}
                                    {key.toLowerCase() === "twitter" && (
                                      <i
                                        className="fa-brands fa-twitter m-2"
                                        style={{ fontSize: "18px" }}
                                      ></i>
                                    )}
                                    {key.toLowerCase() === "others" && (
                                      <i
                                        className="fa-solid fa-hashtag m-2"
                                        style={{ fontSize: "18px" }}
                                      ></i>
                                    )}
                                  </a>
                                );
                              })
                            )}
                          </div>
                        ) : (
                          <span>No Social Media</span>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <h5>
                        <i class="fa-regular fa-file-lines m-3"></i>ABOUT ME
                      </h5>
                      <p style={{ marginLeft: "20px" }}>{about}</p>
                    </div>
                  </div>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleDone}>
                Done
              </Button>
              <Button color="secondary" onClick={toggleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </Fragment>
  );
}

// Styles
const styles = {
  formContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "100%",
    // maxWidth: "500px",
    margin: "0 auto",
    // border: '1px solid #ccc',
    borderRadius: "8px",
  },
  fieldRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginRight: "10px",
  },
  label: {
    marginBottom: "8px",
    fontWeight: "bold",
  },
  selectInput: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  textInput: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    marginTop: "5px",
    marginBottom: "5px",
  },
  addButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  container: {
    PaddingTop: "20px",
    maxWidth: "600px",
    margin: "auto",
    backgroundColor: "#e9ecef",
    borderRadius: "10px",
    // boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  },
  attributeList: {
    padding: "10px",
    borderRadius: "10px",
    marginBottom: "20px",
  },
  attributeRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  attributeLabel: {
    fontSize: "16px",
    color: "#212529",
    marginRight: "10px",
    borderRadius: "5px",
    padding: "7px",
    width: "30%",
    backgroundColor: "transparent",
    border: "3px solid #ffffff",
  },
  input: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #007bff",
    flexGrow: "1",
  },
  selectedAttributes: {
    marginTop: "20px",
  },
  selectedAttributeRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#f1f7ff",
    borderRadius: "10px",
    marginBottom: "10px",
  },
  dropdown: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    backgroundColor: "#e0efff",
    borderRadius: "10px",
    alignItems: "center",
    marginBottom: "10px",
    cursor: "pointer",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#6c757d",
  },
  arrow: {
    fontSize: "20px",
    color: "#007bff",
  },
};

export default FormCommon;
